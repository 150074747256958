import React, { useState, useEffect } from "react";
import { db, auth } from "../Utils/firebase";
import { FOLD_TYPES, FONTS, IMAGE_TYPES } from "../Utils/Constants";
import { getHeightCM, useQuery } from "../Utils/Utils";
import { saveBook, deleteBook } from "../Utils/BookAPI";

import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ImageIcon from "@material-ui/icons/Image";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";

import FileInput from "../Components/FileInput";
import Canvas from "../Components/ImagePreview";
import Snackbar from "../Components/Snackbar";
import Loading from "../Components/Loading";
import TextPreview from "../Components/TextPreview";
import PatternViewer from "./PatternViewer";
import TabPanel from "../Components/TabPanel";

const defaultValues = {
  name: "Title",
  height: 240,
  lastOddPage: 499,
  foldType: FOLD_TYPES.MMCF,
  tabType: IMAGE_TYPES.IMAGE,
  text: "Jovie",
  textFont: FONTS[0],
  textSize: 50,
  dataURL: undefined,
  imageURL: undefined,
  textURL: undefined,
};

function Book() {
  let query = useQuery();
  let id = query.get("id") ? query.get("id") : "";

  //Input State
  const [name, setName] = useState(defaultValues.name);
  const [height, setHeight] = useState(defaultValues.height);
  const [lastOddPage, setLastOddPage] = useState(defaultValues.lastOddPage);
  const [foldType, setFoldType] = useState(defaultValues.foldType);
  const [tabType, setTabType] = useState(defaultValues.tabType);
  const [text, setText] = useState(defaultValues.text);
  const [textFont, setTextFont] = useState(defaultValues.textFont);
  const [textSize, setTextSize] = useState(defaultValues.textSize);

  //Image data URLs
  const [dataURL, setDataURL] = useState(defaultValues.dataURL); //Data URL of fetched book from firebase
  const [textURL, setTextURL] = useState(defaultValues.textURL); //Current text URL
  const [imageURL, setImageURL] = useState(defaultValues.imageURL); //Current image URL

  //Component State
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disableButtons, setDisableButtons] = useState(false);
  const [showPatternViewer, setshowPatternViewer] = useState(false);

  //Call for data
  useEffect(() => {
    if (id) {
      //Make call for data
      async function getBookData() {
        const bookRef = db
          .collection("users")
          .doc(auth.currentUser.uid)
          .collection("books")
          .doc(id);
        const doc = await bookRef.get();
        if (doc.exists) {
          const data = doc.data();
          setName(data.name);
          setHeight(data.height);
          setLastOddPage(data.lastOddPage);
          setFoldType(data.foldType);
          setTabType(data.imageType);
          setDataURL(data.imageURL);
          switch (data.imageType) {
            case IMAGE_TYPES.LIBRARY:
              break;
            case IMAGE_TYPES.TEXT:
              setText(data.text);
              setTextFont(data.textFont);
              setTextSize(data.textSize);
              setImageURL(data.imageURL);
              break;
            case IMAGE_TYPES.IMAGE:
              setImageURL(data.imageURL);
              break;
            default:
              break;
          }
        } else {
          //Error returning data
          setSnackbar({
            text: "Error loading book",
            severity: "error",
          });
          window.location.href = "/";
        }
      }
      getBookData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id]);

  //Data URL
  const DataURL = {
    original: dataURL,
    current: () => {
      switch (tabType) {
        case IMAGE_TYPES.TEXT:
          return textURL;
        case IMAGE_TYPES.IMAGE:
          return imageURL;
        default:
          //Error invalid tab selected
          break;
      }
    },
  };

  //Inputs
  const Name = {
    value: name,
    onChange: (e) => {
      setName(e.target.value);
    },
    isValid: () => {
      return name;
    },
  };
  const Height = {
    value: height,
    valueFormatted: getHeightCM(height),
    onChange: (e) => {
      const rounded = Math.round(e.target.value * 10) / 10;
      const height = Number(rounded) * 10;
      setHeight(height);
    },
    isValid: () => {
      return height && height > 0 && height < 500;
    },
  };
  const LastOddPage = {
    value: lastOddPage,
    valueFormatted: String(lastOddPage).replace(/^0+/, ""),
    onChange: (e) => {
      const value = Number(e.target.value);
      setLastOddPage(Math.trunc(value));
    },
    isValid: () => {
      return (
        lastOddPage &&
        lastOddPage >= 1 &&
        lastOddPage % 2 !== 0 &&
        lastOddPage <= 2500
      );
    },
  };
  const FoldType = {
    value: foldType,
    onChange: (e) => {
      setFoldType(e.target.value);
    },
  };
  const TabType = {
    value: tabType,
    onChange: (e, v) => {
      setTabType(v);
    },
  };
  const Text = {
    value: text,
    onChange: (e) => {
      setText(e.target.value.substring(0, 25));
    },
    isValid: () => {
      return text;
    },
  };
  const TextFont = {
    value: textFont,
    onChange: (e) => {
      setTextFont(e.target.value);
    },
  };
  const TextSize = {
    value: textSize,
    onChange: (e, v) => {
      setTextSize(v);
    },
  };

  //Buttons
  const ViewPattern = {
    isDisabled: () => {
      let isValid = Name.isValid() && Height.isValid() && LastOddPage.isValid();
      switch (TabType.value) {
        case IMAGE_TYPES.TEXT:
          isValid = isValid && Text.isValid() && textURL;
          break;
        case IMAGE_TYPES.IMAGE:
          isValid = isValid && imageURL;
          break;
        case IMAGE_TYPES.LIBRARY:
        default:
          break;
      }
      return !isValid || disableButtons;
    },
    onClick: () => {
      setshowPatternViewer(true);
    },
  };
  const Save = {
    isDisabled: ViewPattern.isDisabled,
    onClick: async () => {
      setDisableButtons(true);
      await saveBook(DataURL.original, {
        id,
        imageType: TabType.value,
        foldType: FoldType.value,
        name: Name.value,
        height: Height.value,
        url: DataURL.current(),
        lastOddPage: LastOddPage.value,
        text: Text.value,
        textFont: TextFont.value,
        textSize: TextSize.value,
      })
        .then((book) => {
          if (!id) {
            window.location.href = "/book?id=" + book.id;
          }
          setDataURL(book.url);
          if (tabType === IMAGE_TYPES.TEXT) {
            setImageURL("");
          }
          if (tabType === IMAGE_TYPES.IMAGE) {
            setTextURL("");
          }
          setSnackbar({
            text: "Pattern saved successfully",
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbar({
            text: "Unable to save your pattern",
            severity: "error",
          });
        })
        .finally(() => {
          setDisableButtons(false);
        });
    },
  };
  const Delete = {
    isDisabled: () => {
      return disableButtons;
    },
    onClick: async () => {
      setDisableButtons(true);
      await deleteBook({ id, url: DataURL.original })
        .then(() => {
          setSnackbar({
            text: "Pattern deleted successfully",
            severity: "success",
          });
          window.location.href = "/";
        })
        .catch((error) => {
          setSnackbar({
            text: "Unable to delete your pattern",
            severity: "error",
          });
        })
        .finally(() => {
          setDisableButtons(false);
        });
    },
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={4}>
          {/* HEADER (Create/Update Book) */}
          <Grid item xs={12}>
            <Typography variant="h2" color="secondary">
              {id ? "Update" : "Create"} Book
            </Typography>
          </Grid>

          {/* NAME */}
          <Grid item xs={12}>
            <TextField
              label="Name"
              variant="standard"
              color="secondary"
              value={Name.value}
              onChange={Name.onChange}
              error={!Name.isValid()}
              helperText={!Name.isValid() && "That value is invalid"}
              fullWidth
            />
          </Grid>

          {/* HEIGHT */}
          <Grid item xs={6}>
            <TextField
              label="Height"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              variant="standard"
              color="secondary"
              type="number"
              step="0.1"
              value={Height.valueFormatted}
              onChange={Height.onChange}
              error={!Height.isValid()}
              helperText={!Height.isValid() && "That value is invalid"}
              fullWidth
            />
          </Grid>

          {/* LAST ODD PAGE */}
          <Grid item xs={6}>
            <TextField
              label="Last Odd Page"
              variant="standard"
              color="secondary"
              type="number"
              value={LastOddPage.valueFormatted}
              onChange={LastOddPage.onChange}
              error={!LastOddPage.isValid()}
              helperText={!LastOddPage.isValid() && "That value is invalid"}
              fullWidth
            />
          </Grid>

          {/* FOLD TYPE */}
          <Grid item xs={12}>
            <FormControl variant="standard" color="secondary" fullWidth>
              <InputLabel id="fold-type-label">Fold Type</InputLabel>
              <Select
                variant="standard"
                color="secondary"
                labelId="fold-type-label"
                value={FoldType.value}
                label="Fold Type"
                onChange={FoldType.onChange}
              >
                {Object.keys(FOLD_TYPES).map(function (key, index) {
                  return (
                    <MenuItem key={index} value={FOLD_TYPES[key]}>
                      {FOLD_TYPES[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* TEXT, IMAGE, LIBRARY */}
          <Grid item xs={12}>
            <Paper square elevation={0}>
              <Tabs
                value={TabType.value}
                onChange={TabType.onChange}
                indicatorColor="primary"
                centered
              >
                <Tab
                  label="Text"
                  color="secondary"
                  icon={<TextFieldsIcon color="secondary" />}
                />
                <Tab
                  label="Image"
                  color="secondary"
                  icon={<ImageIcon color="secondary" />}
                />
                <Tab
                  label="Library"
                  color="secondary"
                  icon={<LocalLibraryIcon color="secondary" />}
                />
              </Tabs>
            </Paper>

            <Paper variant="outlined" square>
              <TabPanel value={TabType.value} index={IMAGE_TYPES.TEXT}>
                <TextTabContent
                  Text={Text}
                  TextFont={TextFont}
                  TextSize={TextSize}
                  Height={Height}
                  LastOddPage={LastOddPage}
                  callBack={setTextURL}
                />
              </TabPanel>
              <TabPanel value={TabType.value} index={IMAGE_TYPES.IMAGE}>
                <ImageTabContent
                  url={{ imageURL: DataURL.current(), setImageURL }}
                />
              </TabPanel>
              <TabPanel value={TabType.value} index={IMAGE_TYPES.LIBRARY}>
                <LibraryTabContent />
              </TabPanel>
            </Paper>
          </Grid>

          {/* VIEW, SAVE, DELETE */}
          <Grid container item xs={12} justify="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={Save.onClick}
              disabled={Save.isDisabled()}
              style={{ marginRight: "5px" }}
            >
              <SaveIcon fontSize="small" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={Delete.onClick}
              disabled={Delete.isDisabled()}
              style={{ marginLeft: "5px" }}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={ViewPattern.onClick}
              disabled={ViewPattern.isDisabled()}
            >
              View Pattern
            </Button>
          </Grid>
        </Grid>
      )}
      {showPatternViewer && (
        <PatternViewer
          open={showPatternViewer}
          onClose={() => {
            setshowPatternViewer(false);
          }}
          book={{
            imageType: tabType,
            name,
            height,
            lastOddPage,
            foldType,
            url: DataURL.current(),
          }}
          rawImageUrl={imageURL}
        />
      )}
      {snackbar && (
        <Snackbar
          callback={() => {
            setSnackbar(null);
          }}
          {...snackbar}
        />
      )}
    </>
  );
}

function TextTabContent(props) {
  const { Text, TextFont, TextSize, Height, LastOddPage, callBack } = props;
  return (
    <Grid container justify="center" alignItems="center">
      {/* TEXT, FONT, SIZE */}
      <Grid
        container
        item
        xs={12}
        md={6}
        spacing={2}
        justify="center"
        alignItems="center"
      >
        {/* TEXT */}
        <Grid item xs={12}>
          <TextField
            label="Text"
            variant="standard"
            color="secondary"
            type="string"
            value={Text.value}
            onChange={Text.onChange}
            error={!Text.isValid()}
            helperText={!Text.isValid() && "That value is invalid"}
            fullWidth
          />
        </Grid>

        {/* FONT */}
        <Grid item xs={12}>
          <FormControl variant="standard" color="secondary" fullWidth>
            <InputLabel id="fold-type-label">Font</InputLabel>
            <Select
              variant="standard"
              color="secondary"
              labelId="fold-type-label"
              value={TextFont.value}
              label="Font"
              onChange={TextFont.onChange}
            >
              {FONTS.map((font) => {
                return (
                  <MenuItem
                    key={font}
                    value={font}
                    style={{ fontFamily: font }}
                  >
                    {font}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        {/* Size */}
        <Grid item xs={12}>
          <Typography variant="subtitle2">Size</Typography>
          <Slider
            value={TextSize.value}
            min={0}
            max={250}
            onChange={TextSize.onChange}
          />
        </Grid>
      </Grid>

      {/* PREVIEW */}
      <Grid
        container
        item
        xs={12}
        md={6}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <TextPreview
          text={Text.value}
          height={Height.value}
          lastOddPage={LastOddPage.value}
          callBack={callBack}
          size={TextSize.value}
          font={TextFont.value}
        />
      </Grid>
    </Grid>
  );
}

function ImageTabContent(props) {
  const { imageURL, setImageURL } = props.url;
  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      {/* FILE INPUT */}
      <Grid container item xs={12} md={6} justify="center" alignItems="center">
        <FileInput callBack={setImageURL} />
      </Grid>

      {/* PREVIEW */}
      <Grid
        container
        item
        xs={12}
        md={6}
        justify="center"
        alignItems="center"
        style={{ minHeight: 250 }}
      >
        {imageURL ? (
          <Canvas imageURL={imageURL} />
        ) : (
          <WallpaperIcon color="disabled" style={{ fontSize: 50 }} />
        )}
      </Grid>
    </Grid>
  );
}

function LibraryTabContent(props) {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Typography variant="h3">Coming Soon</Typography>
      <Typography variant="subtitle1" gutterBottom>
        A full library of free images to choose from is currently being
        developed
      </Typography>
    </Grid>
  );
}

export default Book;
