import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ImageIcon from "@material-ui/icons/Image";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import CustomizedSnackbar from "../Components/Snackbar";
import PDFDownload from "../Utils/PDFDownload";
import { getHeightCM, getCutMeasurement } from "../Utils/Utils";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { BRAND_COLORS } from "../Utils/Constants";
import { IMAGE_TYPES, THRESHOLD, rgba } from "../Utils/Constants";
import Jimp from "jimp";
import { JIMP_COLORS, PATTERNVIEW_TYPES } from "../Utils/Constants";
import GeneratePattern from "../Utils/PatternCreator";
import { EVENT, logEvent } from "../Utils/Utils";
import Loading from "../Components/Loading";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.up("xs")]: {
      minHeight: "56px",
    },
  },
  flex: {
    flexGrow: 1,
  },
  card: {
    marginBottom: "16px",
  },
}));

function PatternViewer(props) {
  const classes = useStyles();
  const [pattern, setPattern] = useState({
    pages: 0,
    height: 0,
    Pages: [],
  });
  const { onClose, open, book, rawImageUrl } = props;
  const { height, lastOddPage, foldType, url, name, imageType } = book;
  const [activeStep, setActiveStep] = React.useState(0);
  const [viewType, setViewType] = React.useState(PATTERNVIEW_TYPES.PREVIEW);
  const [snackbar, setSnackbar] = useState(null);
  const [showDownload, setShowdownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resizedUrl, setResizedUrl] = useState(undefined);
  const [jimpRawImage, setJimpRawImage] = useState(undefined);

  const handleViewTypeChange = (event, newViewType) => {
    setViewType(newViewType);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClose = () => {
    onClose();
  };

  const getPages = useCallback(() => {
    return (lastOddPage + 1) / 2;
  }, [lastOddPage]);

  //show/hide download
  useEffect(() => {
    if (!pattern) {
      setShowdownload(false);
      return;
    }

    if (viewType === PATTERNVIEW_TYPES.PREVIEW) {
      setShowdownload(false);
    } else if (viewType === PATTERNVIEW_TYPES.PAGE) {
      setShowdownload(false);
    } else if (viewType === PATTERNVIEW_TYPES.IMAGE) {
      if (pattern.height >= 254) {
        //Height is less than 10inches
        setShowdownload(false);
      } else {
        setShowdownload(true);
      }
    } else if (viewType === PATTERNVIEW_TYPES.GRID) {
      setShowdownload(true);
    } else {
      setShowdownload(false);
    }
  }, [pattern, viewType]);

  //Create initial folds Pattern
  useEffect(() => {
    const callGeneratePattern = async () => {
      await GeneratePattern({
        resizedImageURL: resizedUrl,
        foldType,
        height,
        lastOddPage,
        imageType,
      })
        .then((p) => {
          setPattern(p);
        })
        .catch((error) => {
          logEvent(EVENT.CATCH, "useEffect", error, "PatternViewer.js");
          setSnackbar({
            text: "Unable to generate your pattern",
            severity: "error",
          });
        });
    };
    callGeneratePattern();
  }, [resizedUrl, foldType, height, lastOddPage, imageType]);

  useEffect(() => {
    if (!url || !rawImageUrl) {
      return undefined;
    }
    const rgbaIdx = imageType === IMAGE_TYPES.TEXT ? rgba.a : rgba.r;
    Jimp.read(url)
      .then((image) => {
        image
          .resize(getPages(), height, Jimp.RESIZE_NEAREST_NEIGHBOR)
          .scan(
            0,
            0,
            image.bitmap.width,
            image.bitmap.height,
            function (x, y, idx) {
              //Threshold each pixel
              let rgbValue = 0;
              if (this.bitmap.data[idx + rgbaIdx] > THRESHOLD) {
                rgbValue = 255;
              }
              //r
              this.bitmap.data[idx + 0] = rgbValue;
              //g
              this.bitmap.data[idx + 1] = rgbValue;
              //b
              this.bitmap.data[idx + 2] = rgbValue;
              //a
              this.bitmap.data[idx + 3] = 255;
            }
          )
          .getBase64(Jimp.AUTO, (err, src) => {
            setResizedUrl(src);
          });
      })
      .catch((error) => {
        logEvent(EVENT.CATCH, "useEffect", error, "Book.js");
        setSnackbar({
          text: "There has been an error generating your pattern",
          severity: "error",
        });
      });
    Jimp.read(rawImageUrl)
      .then((image) => {
        image.getBase64(Jimp.AUTO, (err, src) => {
          setLoading(false);
          setJimpRawImage(src);
        });
      })
      .catch((error) => {
        setSnackbar({
          text: "There has been an error generating your pattern",
          severity: "error",
        });
      });
  }, [getPages, height, imageType, url, rawImageUrl]);

  return (
    <>
      {snackbar && (
        <CustomizedSnackbar
          callback={() => {
            setSnackbar(null);
          }}
          {...snackbar}
        />
      )}

      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <AppBar position="fixed" color="secondary">
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1 }}></Typography>
            {showDownload && (
              <PDFDownload
                pattern={pattern}
                viewType={viewType}
                name={name}
                foldType={foldType}
                lastOddPage={lastOddPage}
                rawImageUrl={jimpRawImage}
              ></PDFDownload>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar className={classes.toolbar} />

        {pattern && viewType === PATTERNVIEW_TYPES.PREVIEW && (
          <DialogContent dividers>
            <div className={classes.card}>
              <Typography variant="h5" component="h2">
                {name}
              </Typography>
              <Typography variant="body2" component="p">
                Last odd Page: {lastOddPage}
              </Typography>
              <Typography variant="body2" component="p">
                {getHeightCM(height)}cm
              </Typography>
              <Typography variant="body2" component="p">
                {foldType}
              </Typography>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: getPages(),
                  height: height,
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: BRAND_COLORS.SECONDARY,
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {pattern.Pages.map((page, index) => (
                    <div
                      key={page.number}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxWidth: "10px",
                      }}
                    >
                      {page.Folds.map((fold, index) => (
                        <div
                          key={index}
                          style={{
                            flexGrow: fold.length,
                            backgroundColor:
                              fold.color === JIMP_COLORS.BLACK
                                ? "grey"
                                : "white",
                          }}
                        ></div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </DialogContent>
        )}
        {pattern && viewType === PATTERNVIEW_TYPES.PAGE && (
          <DialogContent
            style={{ display: "flex", flexDirection: "column" }}
            dividers
          >
            <div style={{ position: "absolute" }}>
              <div
                style={{
                  width: "75px",
                  padding: "5px",
                  borderRight: "2px dashed grey",
                  borderTop: "2px dashed grey",
                  borderBottom: "2px dashed grey",
                  marginBottom: "5px",
                }}
              >
                Fold
              </div>
              <div
                style={{
                  width: "75px",
                  padding: "5px",
                  borderRight: "2px solid grey",
                  borderTop: "2px solid grey",
                  borderBottom: "2px solid grey",
                  marginBottom: "5px",
                }}
              >
                No Fold
              </div>
            </div>
            <SwipeableViews
              style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
              axis={"x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              containerStyle={{ flexGrow: 1, paddingBottom: "16px" }}
              enableMouseEvents
            >
              {pattern.Pages.map((page, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                      padding: "40px",
                    }}
                  >
                    <div>p.</div>
                    <div style={{ fontSize: "2.5em" }}>{page.number}</div>
                  </div>

                  {/* Container */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {page.Folds.map((fold, index) => (
                      //children
                      <div
                        key={index}
                        style={{
                          flexGrow: fold.length,
                          borderTop:
                            fold.depth > 0
                              ? "2px dashed grey"
                              : "2px solid grey",
                          borderRight:
                            fold.depth > 0
                              ? "2px dashed grey"
                              : "2px solid grey",
                          borderBottom:
                            fold.depth > 0
                              ? "2px dashed grey"
                              : "2px solid grey",
                          width: "75px",
                          display: "flex",
                          alignItems: "flex-end",
                          minHeight: "10px",
                        }}
                      >
                        {index === page.Folds.length - 1
                          ? ""
                          : getHeightCM(getCutMeasurement(page.Folds, index)) +
                            " cm"}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </SwipeableViews>
            <MobileStepper
              steps={getPages()}
              position="static"
              variant="progress"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === getPages() - 1}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </DialogContent>
        )}
        {pattern && viewType === PATTERNVIEW_TYPES.GRID && (
          <DialogContent dividers>
            <List>
              {pattern.Pages.map((page, index) => (
                <>
                  <ListItem
                    selected={activeStep === index}
                    onClick={() => handleStepChange(index)}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{ display: "flex", width: "40px", padding: 10 }}
                      >
                        p.
                        <div style={{ fontWeight: "bold" }}>{page.number}</div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      {page.Folds.map((fold, index) => (
                        <>
                          {index === page.Folds.length - 1 ? (
                            <></>
                          ) : (
                            <>
                              <div style={{ width: "35px", padding: 10 }}>
                                {getHeightCM(
                                  getCutMeasurement(page.Folds, index)
                                )}
                              </div>
                              <Divider orientation="vertical" flexItem />
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </DialogContent>
        )}
        {pattern && viewType === PATTERNVIEW_TYPES.IMAGE && (
          <DialogContent dividers>
            <div
              style={{ display: "flex", alignItems: "stretch", height: "100%" }}
            >
              {pattern.Pages.map((page, index) => (
                <>
                  {/* page */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRight: "1px solid grey",
                      borderTop: "1px solid grey",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <div
                      style={{ borderBottom: "1px solid grey", padding: "5px" }}
                    >
                      {page.number}
                    </div>

                    {page.Folds.map((fold, index) => (
                      <div
                        style={{
                          flexGrow: fold.length,
                          backgroundColor:
                            fold.color === JIMP_COLORS.BLACK ? "grey" : "white",
                        }}
                      />
                    ))}

                    <div
                      style={{ borderTop: "1px solid grey", padding: "5px" }}
                    >
                      {page.number}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </DialogContent>
        )}
        <BottomNavigation value={viewType} onChange={handleViewTypeChange}>
          <BottomNavigationAction
            label="Preview"
            value={PATTERNVIEW_TYPES.PREVIEW}
            icon={<ImageIcon />}
          />
          <BottomNavigationAction
            label="Page"
            value={PATTERNVIEW_TYPES.PAGE}
            icon={<LineStyleIcon />}
          />
          <BottomNavigationAction
            label="Grid"
            value={PATTERNVIEW_TYPES.GRID}
            icon={<ViewComfyIcon />}
          />
          <BottomNavigationAction
            label="Print"
            value={PATTERNVIEW_TYPES.IMAGE}
            icon={<ImageIcon />}
          />
        </BottomNavigation>
      </Dialog>
    </>
  );
}

export default PatternViewer;
